@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

// primary

$color-primary: #414a53;
$font-primary: 'Inter', sans-serif;

  // fonts

$layout-font: 'Open Sans', sans-serif;

  // colors

$dgray: #414a53;
$bgrey: #5A6A85;
$mgray: #95999e;
$lgray: #d8dde1;
$white: #FFFFFF;
$green: #29a948;
$dark-green: #238f3d;
$blue: #5D87FF;
$dark-blue: #4f73d9;
$red: #e52b2b;
$dark-red: #cb2727;

  // background colors

$bg-lgray: #f3f6f5;
$bg-dgray: #31373f;
$bg-white: #ffffff;
$bg-blue: #1f415f;