@use "./vars.scss";

.services {
    .box-count--white {
        color: vars.$white;
    }
    .box-count--green {
        color: vars.$green;
    }
}
.fw-7{
    font-weight: 700;
}
.services__title__box {
    max-width: min-content;
    padding:0 20px 20px 0;
    align-self: flex-start;
}

.services__text__box {
    flex: 1 0 30%;
    padding-bottom: 20px;
}