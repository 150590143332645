@import "vars";
@import "calculator";
@import "about";
@import "../libs/helper.min";

*, body{
  padding: 0;
  margin: 0;
}

body{
  &::-webkit-scrollbar{
    background: $lgray;
    width: 10px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-thumb{
    background: $green;
    //border-radius: 3px;
  }
}

.pMarginDel {
  & > p{
    margin-bottom: 0;
  }
}

.languageSelect{
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  &__btn{
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    text-transform: uppercase;
  }
  &__values{
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    top: 25px;
    backdrop-filter: blur(3px);
    border-radius: 5px;
    a{
      color: #000;
      text-decoration: none;
      letter-spacing: 0.5px;
      transition: 0.2s;
      text-transform: uppercase;
      &:hover{
        color: $green;
      }
    }
  }
}

.container{
  max-width: 1440px;
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 1350px){
    width: 90%;
  }
}

.header{
  position: absolute;
  width: 100%;
  z-index: 10;
  .sub-header{
    font-family: $layout-font;
    color: $white;
    position: relative;
    background: linear-gradient(90deg, rgba(255,255,255,1) 26%, $bg-blue 27%);
    padding: 20px 0;
    z-index: 1;
    @media screen and (max-width: 1120px){
      background: #fff;
      .mob-menu-btn{
        display: block;
      }
      .logo{
        width: auto;
        clip-path: none;
        height: auto;
        img{
          width: 150px;
        }
      }
    }
  }
  .container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .logo{
    width: 30%;
    cursor: pointer;
    background: #fff;
    height: 100%;
    position: absolute;
    left: 0;
    display: flex;
    align-content: center;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
    box-sizing: border-box;
    margin-left: 10%;
    z-index: 1;
    img{
      width: 250px;
      object-fit: contain;
      @media screen and (max-width: 1170px){
        width: 200px;
      }
    }
    @media screen and (max-width: 1350px){
      margin-left: 5%;
    }
  }
  &__right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    &__top{
      display: flex;
      align-items: center;
      gap: 50px;
      padding-bottom: 15px;
      @media screen and (max-width: 1465px){
        gap: 30px;
      }
      &--call, &--mail{
        display: flex;
        align-items: center;
        gap: 10px;
        svg{
          width: 35px;
          height: 35px;
          path{
            color: $green;
          }
        }
        & > div{
          b{
            font-weight: 600;
            display: block;
            line-height: 15px;
            font-size: 15px;
            white-space: nowrap;
          }
          a{
            color: $white;
            text-decoration: none;
            font-size: 13px;
            white-space: nowrap;
          }
        }
      }
      &--btn{
        color: $white;
        text-transform: uppercase;
        padding: 13px 25px;
        background: $green;
        text-decoration: none;
        border-radius: 5px;
        transition: 0.3s;
        white-space: nowrap;
        &:hover{
          background: $white;
          color: $green;
          //font-weight: 500;
        }
      }
    }
    .dec-line{
      height: 1px;
      width: 100%;
      background: $white;
      position: absolute;
      right: 0;
      top: 25px;
      bottom: 0;
      margin: auto 0;
    }
    &__bottom{
      display: flex;
      align-items: center;
      gap: 30px;
      align-self: flex-start;
      padding-top: 15px;
      width: 100%;
      justify-content: space-between;
      a{
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        transition: 0.2s;
        white-space: nowrap;
        &:hover, &.active{
          color: $green;
        }
      }
    }
    @media screen and (max-width: 970px){
      width: 65%;
    }
    @media screen and (max-width: 1120px){
      display: none;
    }
  }
  .mob-menu-btn{
    display: none;
  }
  .dropdown{
    position: relative;
    &__menu{
      position: absolute;
      background: $bg-blue;
      display: flex;
      flex-direction: column;
      padding: 10px 12px 5px;
      overflow: hidden;
      transition: 0.4s;
      left: -10px;
      a{
        white-space: nowrap;
        padding: 10px 0;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        &:last-child{
          border: none;
        }
      }
    }
  }
}

.mob-menu{
  background: $bg-blue;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  overflow: hidden;
  transition: 0.4s;
  transform: translateY(-100%);
  a{
    color: $white;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    font-family: $layout-font;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    &:last-child{
      border: none;
    }
    &.active{
      color: $green;
    }
  }
  &--perv{
    margin-left: 10px;
  }
  &.active{
    transform: translateY(0);
  }
  @media screen and (min-width: 1120px){
    display: none;
  }
}

.contactus{
  &__details{
    display: flex;
    position: relative;
    z-index: 3;
    gap: 80px;
    overflow-x: scroll;
    padding-bottom: 20px;
    &::-webkit-scrollbar{
      height: 5px;
      background: $lgray;
    }
    &::-webkit-scrollbar-thumb{
      background: $green;
    }

    a{
      &:hover{
        color: $green;
      }
      svg{
        &:hover{
          color: $green;
        }
      }
    }
  }
}

.contacts-item__img{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #003d90;
}

.footerColorSvg{
  path{
    fill: #fff
  }
}

.contactHoverSvg{
  margin-bottom: 5px;
  path{
    transition: 0.2s;
  }
  &:hover path{
    fill: $green;
  }
}

.form__field{
  //&:invalid{
  //  border-color: #e2e2e2;
  //  &::placeholder{
  //    color: #414a53;
  //  }
  //}
}

.button--green{
  border-color: $green;
  &:hover{
    background: $green;
  }
}

.footer{
  &__top{
    display: flex;
    & > div{
      width: auto;
    }
    &__scroll{
      display: flex;
      gap: 40px;
      overflow-x: scroll;
      margin-left: 20px;
      &::-webkit-scrollbar{
        height: 3px;
        background: $lgray;
      }
      &::-webkit-scrollbar-thumb{
        background: $green;
      }
    }
  }
  &__middle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    img{
      height: 50px;
    }
    @media screen and (max-width: 490px){
      flex-direction: column;
    }
  }
}

.page-footer__menu{
  li{
    width: auto;
    white-space: nowrap;
  }
}

.slider-control-bottomcenter{
  & > div{
    bottom: 30px !important;
  }
}


.loader {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 20;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-box {
  display: flex;
  justify-content: center;
}

.singleServiceContent{
  p, ul{
    color: #414a53;
    font-size: 16px;
    line-height: 1.6;
  }
  @extend .helperCss;
  ul{
    padding-left: 30px;
  }
  li{
    line-height: 32px;
    position: relative;
    &:not(:last-of-type){
      margin-bottom: 8px;
    }
    &:before{
      border-left: 2px solid #00997d;
      border-top: 2px solid #00997d;
      content: "";
      display: block;
      height: 10px;
      left: -23px;
      position: absolute;
      top: 10px;
      transform: rotate(-135deg);
      width: 6px;
      z-index: 1;
    }
    &:after{
      background: #f3f6f5;
      border-radius: 50%;
      content: "";
      display: block;
      height: 20px;
      left: -30px;
      position: absolute;
      top: 6px;
      width: 20px;
    }
  }
  img{
    width: 100%;
  }
}